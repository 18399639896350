import React from "react";

const ConditionGenerale = () => {
  const paragraphStyle = { marginBottom: "15px", textAlign: "left", paddingLeft: "5px" };
  const headingStyle = { marginBottom: "10px", textAlign: "left", paddingLeft: "5px" };
  const listStyle = { marginBottom: "15px", textAlign: "left", paddingLeft: "5px", listStyleType: "disc", marginLeft: "20px" };

  return (
    <div style={{ backgroundColor: "#262626", minHeight: "100vh", color: "white", padding: "20px 5px" }}>
      <div style={{ maxWidth: "800px", margin: "0 auto" }}>
        <h1 style={headingStyle}>Conditions Générales d'Utilisation de Despii</h1>
        <p style={paragraphStyle}>Date de mise à jour : Version applicable à compter du 29 juillet 2024 ou, le cas échéant, plus tôt, si vous acceptez explicitement ces Conditions Générales, notamment lors de la création d'un compte ou d'une réservation en ligne.</p>
        <hr style={{ borderColor: "white", margin: "10px 0" }} />
        <div>
          <h2 style={headingStyle}>1. Objet</h2>
          <p style={paragraphStyle}>Despii (ci-après, « Despii ») édite une plateforme de co-scooting, accessible sous forme d’application mobile, destinée à mettre en relation des conducteurs et des passagers partageant le même trajet afin de réduire les coûts et les émissions de CO2 (ci-après, la « Plateforme »).</p>
          <p style={paragraphStyle}>Les présentes conditions générales d’utilisation (ci-après, « CGU ») encadrent l’accès et les modalités d’utilisation de la Plateforme. En utilisant la Plateforme, vous acceptez ces CGU.</p>

          <h2 style={headingStyle}>2. Définitions</h2>
          <ul style={listStyle}>
            <li><strong>Despii :</strong> Société Despii.</li>
            <li><strong>CGU :</strong> Conditions Générales d’Utilisation.</li>
            <li><strong>Compte :</strong> Compte utilisateur créé pour accéder aux services de la Plateforme.</li>
            <li><strong>Conducteur :</strong> Membre proposant de transporter une personne en contrepartie d'une Participation aux Frais.</li>
            <li><strong>Passager :</strong> Membre réservant une place pour un trajet.</li>
            <li><strong>Participation aux Frais :</strong> Somme versée par le Passager pour couvrir les frais du trajet.</li>
            <li><strong>Trajet :</strong> Parcours proposé par le Conducteur et réservé par le Passager.</li>
            <li><strong>Réservation :</strong> Acceptation d'une demande de trajet par un Conducteur ou un Passager.</li>
            <li><strong>Prestataire de paiement :</strong> Entité responsable des transactions financières.</li>
          </ul>

          <h2 style={headingStyle}>3. Inscription et Création de Compte</h2>
          <h3 style={headingStyle}>3.1 Conditions d’inscription à la Plateforme</h3>
          <p style={paragraphStyle}>L’inscription est réservée aux personnes physiques âgées de 18 ans ou plus. Toute inscription par une personne mineure est strictement interdite. En accédant, utilisant ou vous inscrivant sur la Plateforme, vous déclarez et garantissez avoir 18 ans ou plus.</p>

          <h3 style={headingStyle}>3.2 Création de Compte</h3>
          <p style={paragraphStyle}>Pour utiliser la Plateforme, vous devez créer un compte, devenir Membre et télécharger l'application mobile. Vous devez fournir des informations exactes et à jour, et accepter les CGU et la Politique de Confidentialité.</p>

          <h3 style={headingStyle}>3.3 Vérification</h3>
          <p style={paragraphStyle}>Despii peut vérifier certaines informations que vous fournissez pour améliorer la transparence, la confiance ou prévenir la fraude. La vérification peut inclure votre numéro de téléphone, adresse e-mail, pièce d'identité, permis de conduire ou une photo des mains du conducteur sur le volant.</p>

          <h3 style={headingStyle}>3.4 Parrainage</h3>
          <p style={paragraphStyle}>Tout Membre peut parrainer un non-Membre via une offre de parrainage. Le Filleul doit télécharger l’application et créer un compte en utilisant le lien ou le code de référence fourni par le Parrain.</p>

          <h2 style={headingStyle}>4. Utilisation des Services</h2>
          <h3 style={headingStyle}>4.1 Publication de Trajet Conducteur</h3>
          <p style={paragraphStyle}>Les Conducteurs peuvent créer et publier des trajets en fournissant des informations exactes et véridiques. Le véhicule doit être en bon état de fonctionnement, avec une assurance valide, et doit obligatoirement être équipé d'un dossier ou d'un top case. Sans cela, le véhicule ne sera pas accepté sur la Plateforme.</p>

          <h3 style={headingStyle}>4.2 Réservation d’une Place</h3>
          <p style={paragraphStyle}>Despii a mis en place un système de réservation de Places en ligne pour les Trajets proposés sur la Plateforme. Les modalités de réservation dépendent du trajet envisagé.</p>
          <p style={paragraphStyle}>Despii propose un moteur de recherche permettant aux utilisateurs de trouver des trajets selon divers critères (provenance, destination, dates). Il est recommandé de consulter ce moteur de recherche pour déterminer l’offre la plus adaptée à vos besoins.</p>

          <h4 style={headingStyle}>4.2.1 Trajet en Co-Scooting</h4>
          <p style={paragraphStyle}>Lorsque le Passager est intéressé par une annonce, il peut effectuer une demande de réservation en ligne. Cette demande peut être acceptée automatiquement ou manuellement par le Conducteur. Au moment de la réservation, le Passager procède au paiement en ligne de la Participation aux Frais. Après validation, le Passager reçoit une confirmation de réservation.</p>
          <p style={paragraphStyle}>Si le Conducteur a choisi de gérer manuellement les demandes, il doit répondre dans un certain délai. À défaut, la demande expire automatiquement et le Passager est remboursé.</p>
          <p style={paragraphStyle}>Dès la confirmation de la réservation, Despii transmet les coordonnées téléphoniques des Membres concernés, sous réserve de leur consentement. Vous êtes responsables de l’exécution du contrat vous liant à l’autre Membre.</p>

          <h3 style={headingStyle}>4.3 Demande de Réservation</h3>
          <p style={paragraphStyle}>Les Passagers peuvent réserver des places en ligne. Le Conducteur peut accepter ou refuser les demandes de réservation. Une fois acceptée, la réservation devient nominative et modifiable uniquement via l'application mobile.</p>

          <h3 style={headingStyle}>4.4 Caractère nominatif de la Réservation</h3>
          <p style={paragraphStyle}>Les réservations sont nominatives. Il est interdit de réserver une place pour le transport d’un objet, d’un animal voyageant seul ou tout élément matériel. Seules les personnes physiques peuvent réserver des trajets.</p>

          <h3 style={headingStyle}>4.5 Proposition de Coscooting</h3>
          <p style={paragraphStyle}>Les Conducteurs peuvent proposer des Coscootings aux Passagers. Les Passagers peuvent accepter ou refuser les propositions. Une fois acceptée, la réservation devient nominative et modifiable uniquement via l'application mobile.</p>

          <h2 style={headingStyle}>5. Participation aux Frais</h2>
          <p style={paragraphStyle}>Les frais de trajet sont négociés directement entre le Conducteur et le Passager. Despii ne fixe pas les tarifs. Les transactions sont sécurisées via un prestataire de paiement.</p>

          <h2 style={headingStyle}>6. Service de paiement sécurisé</h2>
          <h3 style={headingStyle}>6.1 Création d’un Compte de paiement</h3>
          <p style={paragraphStyle}>Les Membres doivent créer un compte de paiement avec notre prestataire pour utiliser le service de paiement sécurisé. Ils doivent fournir des informations exactes qui pourront être vérifiées.</p>

          <h3 style={headingStyle}>6.2 Paiement de la Participation aux frais</h3>
          <p style={paragraphStyle}>Le paiement des frais de participation est effectué par le Passager via un compte séquestre sécurisé jusqu'à la validation du trajet par la Plateforme.</p>

          <h3 style={headingStyle}>6.3 Transfert vers un compte personnel</h3>
          <p style={paragraphStyle}>Les Membres peuvent transférer leur solde vers un compte bancaire personnel après vérification de leur identité et de leurs coordonnées bancaires.</p>

          <h3 style={headingStyle}>6.3 Transfert vers un compte personnel</h3>
          <p style={paragraphStyle}>Les Membres peuvent transférer leur solde vers un compte bancaire personnel après vérification de leur identité et de leurs coordonnées bancaires.</p>

          <h3 style={headingStyle}>6.4 Versement de la Participation aux Frais du Conducteur</h3>
          <p style={paragraphStyle}>Le Conducteur reçoit le paiement des frais de participation après validation du trajet par la Plateforme. Les Passagers disposent de 24 heures pour contester le trajet.</p>

          <h2 style={headingStyle}>7. Finalité non commerciale et non professionnelle des Services et de la Plateforme</h2>
          <p style={paragraphStyle}>Vous vous engagez à utiliser la Plateforme uniquement à des fins non professionnelles et non commerciales.</p>

          <h2 style={headingStyle}>8. Politique d’annulation</h2>
          <p style={paragraphStyle}>Les annulations doivent être faites via l’application. Les remboursements sont effectués selon les conditions définies par Despii. En cas de contestation, les utilisateurs peuvent contacter le service Support de Despii.</p>

          <h2 style={headingStyle}>9. Service de Messagerie</h2>
          <h3 style={headingStyle}>9.1 Accès à la Messagerie</h3>
          <p style={paragraphStyle}>Seuls les Membres peuvent communiquer via la messagerie de l'application mobile.</p>

          <h3 style={headingStyle}>9.2 Utilisation de la Messagerie</h3>
          <p style={paragraphStyle}>La messagerie doit être utilisée pour organiser les trajets. Les messages doivent être respectueux et conformes aux CGU.</p>

          <h2 style={headingStyle}>10. Comportement des Utilisateurs de la Plateforme et Membres</h2>
          <h3 style={headingStyle}>10.1 Engagement de tous les utilisateurs de la Plateforme</h3>
          <p style={paragraphStyle}>Les utilisateurs doivent respecter toutes les lois et règlements applicables et utiliser la Plateforme de manière honnête et responsable.</p>

          <h3 style={headingStyle}>10.2 Engagements des Conducteurs</h3>
          <p style={paragraphStyle}>Les Conducteurs doivent respecter les règles de conduite et de sécurité, fournir des informations exactes sur leurs trajets, et assurer que leurs véhicules sont en bon état de fonctionnement et assurés.</p>

          <h3 style={headingStyle}>10.3 Engagements des Passagers</h3>
          <p style={paragraphStyle}>Les Passagers doivent adopter un comportement respectueux, payer les frais de participation convenus, et fournir des informations exactes sur leur identité.</p>

          <h3 style={headingStyle}>10.4 Signalement du contenu inapproprié des membres</h3>
          <p style={paragraphStyle}>Les utilisateurs peuvent signaler du contenu suspect, inapproprié ou illégal à Despii, qui se réserve le droit de suspendre immédiatement le compte signalé.</p>

          <h2 style={headingStyle}>11. Suspension de comptes, limitation d’accès et résiliation</h2>
          <p style={paragraphStyle}>Despii peut suspendre ou résilier un compte en cas de non-respect des CGU, de fraude, ou d'utilisation commerciale de la Plateforme.</p>

          <h2 style={headingStyle}>12. Données personnelles</h2>
          <p style={paragraphStyle}>Despii collecte et traite vos données personnelles conformément à la loi applicable et à sa Politique de Confidentialité.</p>

          <h2 style={headingStyle}>13. Propriété intellectuelle</h2>
          <h3 style={headingStyle}>13.1 Contenu publié par Despii</h3>
          <p style={paragraphStyle}>Despii détient tous les droits de propriété intellectuelle sur la Plateforme et son contenu.</p>

          <h3 style={headingStyle}>13.2 Contenu publié par vous sur la Plateforme</h3>
          <p style={paragraphStyle}>Vous concédez à Despii une licence non exclusive pour utiliser les contenus que vous publiez sur la Plateforme.</p>

          <h2 style={headingStyle}>14. Rôle de Despii</h2>
          <h3 style={headingStyle}>14.1 Mise en relation</h3>
          <p style={paragraphStyle}>Despii est une plateforme de mise en relation entre Conducteurs et Passagers. Despii facilite uniquement la mise en relation entre les Membres pour des trajets partagés, mais n’intervient pas dans les contrats ou accords conclus entre les Membres.</p>

          <h3 style={headingStyle}>14.2 Absence de contrôle</h3>
          <p style={paragraphStyle}>Despii ne contrôle ni la validité, ni la légalité, ni la qualité des trajets proposés par les Conducteurs. La responsabilité de Despii ne peut être engagée en cas de litige entre les Membres. Les Conducteurs sont entièrement responsables de la conformité de leurs trajets aux lois et règlements en vigueur, ainsi qu'à la sécurité de leurs véhicules.</p>

          <h3 style={headingStyle}>14.3 Limitation de responsabilité</h3>
          <p style={paragraphStyle}>Despii ne peut être tenu responsable des comportements ou actions des Membres avant, pendant et après les trajets. La responsabilité de Despii se limite à fournir une plateforme technique facilitant la mise en relation. En aucun cas, Despii ne peut être tenu responsable des dommages directs ou indirects résultant de l'utilisation de la Plateforme, y compris, mais sans s'y limiter, les pertes de profit, les pertes d'opportunités, ou les préjudices commerciaux.</p>

          <h3 style={headingStyle}>14.4 Exclusion de responsabilité en cas de force majeure</h3>
          <p style={paragraphStyle}>Despii ne peut être tenu responsable en cas de force majeure, telle que définie par la jurisprudence française, empêchant l'exécution des services de la Plateforme. Cela inclut, mais sans s'y limiter, les catastrophes naturelles, les pandémies, les actes de terrorisme, les grèves, ou les interruptions des réseaux de communication.</p>

          <h3 style={headingStyle}>14.5 Garantie limitée</h3>
          <p style={paragraphStyle}>Despii ne garantit pas que la Plateforme sera exempte d'erreurs ou accessible en permanence. Despii se réserve le droit de suspendre temporairement l'accès à la Plateforme pour des raisons de maintenance, de mises à jour, ou pour toute autre raison jugée nécessaire.</p>

          <h2 style={headingStyle}>15. Fonctionnement, disponibilité et fonctionnalités de la Plateforme</h2>
          <h3 style={headingStyle}>15.1 Accès à la Plateforme</h3>
          <p style={paragraphStyle}>Despii s’efforcera de maintenir la Plateforme accessible 24/7. Cependant, l’accès peut être temporairement suspendu pour maintenance, mises à jour, ou pour toute autre raison jugée nécessaire par Despii.</p>

          <h3 style={headingStyle}>15.2 Maintenance et mises à jour</h3>
          <p style={paragraphStyle}>Despii peut être amenée à effectuer des opérations de maintenance ou des mises à jour de la Plateforme. Despii fera ses meilleurs efforts pour informer les Membres de toute interruption programmée des services, mais ne saurait être tenue responsable des éventuelles perturbations de l'accès à la Plateforme.</p>

          <h3 style={headingStyle}>15.3 Fonctionnalités</h3>
          <p style={paragraphStyle}>Despii se réserve le droit de modifier, ajouter, ou supprimer des fonctionnalités de la Plateforme à tout moment et sans préavis. Les Membres seront informés des changements significatifs affectant l'utilisation des services.</p>

          <h2 style={headingStyle}>16. Modification des CGU</h2>
          <h3 style={headingStyle}>16.1 Droit de modification</h3>
          <p style={paragraphStyle}>Despii peut modifier les présentes CGU à tout moment pour s’adapter à son environnement technologique et commercial, ou pour se conformer à la législation en vigueur.</p>

          <h3 style={headingStyle}>16.2 Notification des modifications</h3>
          <p style={paragraphStyle}>Les modifications seront publiées sur la Plateforme et/ou notifiées par e-mail aux Membres. L'utilisation continue de la Plateforme après publication des modifications constitue une acceptation des nouvelles CGU.</p>

          <h3 style={headingStyle}>16.3 Refus des modifications</h3>
          <p style={paragraphStyle}>Si un Membre n'accepte pas les modifications des CGU, il peut résilier son Compte conformément à l'article 11 des présentes CGU. La résiliation doit être effectuée avant la date d'entrée en vigueur des nouvelles CGU.</p>

          <h2 style={headingStyle}>17. Droit applicable et litiges</h2>
          <h3 style={headingStyle}>17.1 Loi applicable</h3>
          <p style={paragraphStyle}>Les présentes CGU sont soumises à la loi française. Toute question relative à leur interprétation et/ou à leur exécution relève des juridictions françaises compétentes.</p>

          <h3 style={headingStyle}>17.2 Résolution des litiges</h3>
          <p style={paragraphStyle}>En cas de litige relatif à l'utilisation de la Plateforme, les Membres s'engagent à tenter de résoudre leur différend à l'amiable avant d'engager une procédure judiciaire. Les Membres peuvent recourir à une médiation conventionnelle ou à tout autre mode alternatif de règlement des différends.</p>

          <h3 style={headingStyle}>17.3 Compétence juridictionnelle</h3>
          <p style={paragraphStyle}>À défaut de résolution amiable, tout litige sera porté devant les tribunaux compétents du ressort de la Cour d'appel de Paris.</p>

          <h2 style={headingStyle}>18. Mentions légales</h2>
          <h3 style={headingStyle}>18.1 Éditeur de la Plateforme</h3>
          <p style={paragraphStyle}>La Plateforme est éditée par Despii, société immatriculée au Registre du Commerce et des Sociétés sous le numéro 93107866100010, dont le siège social est situé au 5 RUE PLEYEL 93200 SAINT-DENIS.</p>

          <h3 style={headingStyle}>18.2 Directeur de la publication</h3>
          <p style={paragraphStyle}>Le directeur de la publication est [Nom du directeur de la publication].</p>

          <h3 style={headingStyle}>18.3 Hébergeur</h3>
          <p style={paragraphStyle}>La Plateforme est hébergée par [Nom de l'hébergeur], dont le siège social est situé au [Adresse de l'hébergeur].</p>

          <h3 style={headingStyle}>18.4 Contact</h3>
          <p style={paragraphStyle}>Pour toute question ou demande d'information concernant la Plateforme ou les présentes CGU, vous pouvez contacter Despii à l'adresse suivante : contact@despii.com</p>
          
          <hr style={{ borderColor: "white", margin: "10px 0" }} />
          <p style={paragraphStyle}>En utilisant la Plateforme, vous acceptez les présentes Conditions Générales d'Utilisation. Nous vous remercions pour votre confiance et vous souhaitons de bons trajets avec Despii.</p>
        </div>
      </div>
    </div>
  );
};

export default ConditionGenerale;
